<template lang="pug">
.forgot

  a.nav-logo(href="https://reservertsøk.no")
    ui-logo

  //- Forgot done
  template(v-if="done")
    .text-center.headline.font-weight-light.mb-12
      | Sjekk innboksen din for ytterligere instruksjoner.
    
    v-layout(justify-center)
      router-link.grey--text.text--darken-1.no-decoration(to="/auth")
        v-icon.mr-2.grey--text mdi-keyboard-backspace
        | Gå tilbake

  v-form(
    v-else
    @submit.prevent
    v-model="form"
    ref="form"
  )

    template(v-if="code && email")
      v-text-field(
        :disabled="true"
        :rules="[rules.required('Oppgi en epost'), rules.validEmail]"
        v-model="email"
        label="Epost"
        type="email"
        append-icon="mdi-email"
        rounded filled
      )
      v-text-field(
        :disabled="loading"
        :rules="[rules.required('Oppgi et passord'), rules.pwdLength]"
        @keydown.enter="submitNewPassword"
        v-model="password"
        label="Nytt passord"
        append-icon="mdi-form-textbox-password"
        type="password"
        rounded filled autofocus
      )
      v-btn.mt-4(
        :disabled="loading || !form"
        :loading="loading"
        @click="submitNewPassword"
        color="primary"
        x-large block depressed rounded
      ) Endre passord

    template(v-else)
      .text-center.grey--text.text--darken-1.mb-6
        | Oppgi epostadressen din for ytterligere instruksjoner.
      v-text-field(
        :disabled="loading"
        :rules="[rules.required('Oppgi en epost'), rules.validEmail]"
        @keydown.enter="submit"
        v-model="email"
        label="Epost"
        type="email"
        append-icon="mdi-email"
        rounded filled autofocus
      )
      v-btn(
        :disabled="loading || !form"
        :loading="loading"
        @click="submit"
        color="primary"
        x-large block depressed rounded
      ) Fortsett
</template>

<script>
import { mapActions } from 'vuex'
import { rules } from '@/mixins'
import { UiLogo } from '@/components/ui'

export default {
  name: 'AuthForgot',
  mixins: [rules],
  components: {
    UiLogo
  },
  data: () => ({
    done: false,
    form: false,
    loading: false,

    email: undefined,
    code: null,
    password: undefined
  }),
  watch: {
    $route: {
      immediate: true,
      handler({ params }) {
        try {
          const { code, data } = params
          this.code = code
          this.email = Buffer.from(data, 'base64').toString()
        } catch (e) {
          /* NOP */
        }
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Cognito', ['forgotPassword', 'changeForgottenPassword']),
    async submit() {
      if (!this.form) return

      this.loading = true
      try {
        this.user = await this.forgotPassword({
          username: this.email
        })
        this.done = true
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    },
    async submitNewPassword() {
      if (!this.form) return

      this.loading = true
      try {
        await this.changeForgottenPassword({
          username: this.email,
          code: this.code,
          newPassword: this.password
        })
        this.setSnackbar({
          type: 'success',
          msg: 'Passordet er nå endret, du kan logge inn'
        })
        this.$router.push(`/auth/${this.email}`)
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
