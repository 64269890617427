<template lang="pug">
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AuthVerify',
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Cognito', ['confirmUser'])
  },
  async mounted() {
    try {
      const { code, data } = this.$route.params
      const username = Buffer.from(data, 'base64').toString()

      this.loading = true
      try {
        await this.confirmUser({ username, code })
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
        this.$gtag.event('sign_up', {
          method: 'Reservertsøk'
        })
        this.setSnackbar({
          type: 'success',
          msg: 'Kontoen er bekreftet. Du kan nå logge inn.'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
        this.$router.push('/auth')
      }
    } catch (e) {
      this.$router.push('/auth')
    }
  }
}
</script>
