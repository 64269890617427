<template lang="pug">
.signin

  a.nav-logo(href="https://reservertsøk.no")
    ui-logo

  v-form(
    @submit.prevent
    v-model="form"
  )

    //- Reset password
    template(v-if="collectNewPassword")
      v-text-field(
        :disabled="loading"
        :rules="[rules.required('Oppgi et passord'), rules.pwdLength]"
        @keydown.enter="submitNewPassword"
        v-model="password"
        ref="collectNewPassword"
        label="Velg ditt nye passord"
        append-icon="mdi-form-textbox-password"
        type="password"
        rounded filled
      )
      v-btn.mt-4(
        :disabled="loading || !form"
        :loading="loading"
        @click="submitNewPassword"
        color="primary"
        x-large block depressed rounded
      ) Lagre

    //- Sign in
    template(v-else)
      v-text-field(
        :disabled="loading"
        :rules="[rules.required('Oppgi en epost'), rules.validEmail]"
        @keydown.enter="submit"
        v-model="email"
        label="Epost"
        type="email"
        append-icon="mdi-email"
        rounded filled autofocus
      )
      v-text-field(
        :disabled="loading"
        :rules="[rules.required('Oppgi et passord')]"
        @keydown.enter="submit"
        v-model="password"
        label="Passord"
        append-icon="mdi-form-textbox-password"
        type="password"
        rounded filled
      )
      .body-2.mb-6.text-right
        router-link.no-decoration(to="/auth/forgot") Glemt passord?
      v-btn(
        :disabled="loading || !form"
        :loading="loading"
        @click="submit"
        color="primary"
        x-large block depressed rounded
      ) Logg inn
      v-layout.mt-6(justify-center)
        router-link.no-decoration(to="/auth/signup") Opprett en ny konto
</template>

<script>
import { mapActions } from 'vuex'
import { rules } from '@/mixins'
import { UiLogo } from '@/components/ui'

export default {
  name: 'AuthSignIn',
  mixins: [rules],
  components: {
    UiLogo
  },
  data: () => ({
    form: false,
    loading: false,
    email: undefined,
    password: undefined,
    collectNewPasswordUser: null,
    collectNewPassword: false
  }),
  watch: {
    $route: {
      immediate: true,
      handler({ params }) {
        try {
          const [email, password] = params.data.split('~')
          this.email = email
          this.password = password
          this.$nextTick(() => this.submit())
        } catch (e) {
          /* NOP */
        }
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Cognito', [
      'signInUser',
      'completeNewPassword',
      'resendConfirmation'
    ]),
    async submit() {
      if (!this.form) return

      this.loading = true
      try {
        this.collectNewPasswordUser = await this.signInUser({
          username: this.email,
          password: this.password
        })

        if (
          this.collectNewPasswordUser.challengeName == 'NEW_PASSWORD_REQUIRED'
        ) {
          this.password = undefined
          this.collectNewPassword = true
          this.$nextTick(() => this.$refs.collectNewPassword.focus())
        } else {
          // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
          this.$gtag.event('login', {
            method: 'Reservertsøk'
          })
          this.$router.push('/')
        }
      } catch (e) {
        let msg = e.message

        if (e.code === 'UserNotConfirmedException') {
          this.resendConfirmation({ username: this.email })
          msg += ` Bekreftelses-e-post sendt på nytt`
        } else {
          msg = 'Feil brukernavn eller passord'
        }

        this.setSnackbar({
          type: 'error',
          msg
        })
      } finally {
        this.loading = false
      }
    },
    async submitNewPassword() {
      if (!this.form) return

      this.loading = true
      try {
        await this.completeNewPassword({
          user: this.collectNewPasswordUser,
          password: this.password
        })
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
        this.$gtag.event('login', {
          method: 'Reservertsøk'
        })
        this.$router.push('/')
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
