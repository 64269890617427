<template lang="pug">
v-container(
  v-if="guard"
  fluid fill-height
)
  v-layout(
    :align-center="$vuetify.breakpoint.mdAndUp"
    justify-center
  )
    v-flex(xs12 sm8 md5 lg4 xl3)
      v-fade-transition(mode="out-in")
        router-view
</template>

<script>
import { authGuard } from '@/mixins'

export default {
  name: 'Auth',
  mixins: [authGuard]
}
</script>

<style lang="stylus" scoped>
.container
  .flex
    position relative

  >>>div
    .no-decoration
      text-decoration none
      color #7b7a7d

    .nav-logo
      margin 60px 0 40px
      display block

      svg
        width 100%
        height 50px
</style>
