<template lang="pug">
.signup

  a.nav-logo(href="https://reservertsøk.no")
    ui-logo

  //- Signup done
  template(v-if="done")
    .text-center.text-h4.font-weight-light.mb-12 Konto opprettet
    .text-center.headline.font-weight-light.mb-6
      | Sjekk innboksen din for å bekrefte den nye kontoen.
    v-btn.mb-12(
      :loading="loading"
      @click="resend"
      color="primary"
      x-large block depressed rounded
    ) Ingen e-post? Send bekreftelse på nytt her.

    v-layout(justify-center)
      router-link.grey--text.text--darken-1.no-decoration(to="/auth")
        v-icon.mr-2.grey--text mdi-keyboard-backspace
        | Gå tilbake

  v-form(
    v-else
    @submit.prevent
    v-model="form"
  )
    v-text-field(
      :disabled="loading"
      :rules="[rules.required('Oppgi ditt fulle navn')]"
      v-model="nickname"
      label="Ditt fulle navn"
      append-icon="mdi-face-outline"
      rounded filled autofocus
    )
    v-text-field(
      :disabled="loading"
      :rules="[rules.required('Oppgi fullt bedriftsnavn')]"
      v-model="company_name"
      label="Bedriftsnavn"
      append-icon="mdi-tag-text"
      rounded filled
    )
    v-text-field(
      :disabled="loading"
      :rules="[rules.required('Oppgi en epost'), rules.validEmail]"
      v-model="email"
      label="Epost"
      type="email"
      append-icon="mdi-email"
      rounded filled
    )
    v-text-field(
      :disabled="loading"
      :rules="[rules.required('Oppgi et passord'), rules.pwdLength]"
      v-model="password"
      label="Passord"
      append-icon="mdi-form-textbox-password"
      type="password"
      rounded filled
    )
    .body-2.mb-6.grey--text.text-center
      | Ved å klikke "opprett konto" godtar du våre 
      router-link.grey--text(
        to="/terms"
      ) vilkår og betingelser
      | .
    v-btn(
      :disabled="loading || !form"
      :loading="loading"
      @click="submit"
      color="primary"
      x-large block depressed rounded
    ) Opprett konto
</template>

<script>
import { mapActions } from 'vuex'
import { rules } from '@/mixins'
import { UiLogo } from '@/components/ui'

export default {
  name: 'AuthSignUp',
  mixins: [rules],
  components: {
    UiLogo
  },
  data: () => ({
    done: false,
    loading: false,
    form: false,

    email: undefined,
    nickname: undefined,
    company_name: undefined,
    password: undefined,
    confirm_password: undefined
  }),
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Cognito', ['registerUser', 'resendConfirmation']),
    async submit() {
      this.loading = true
      try {
        await this.registerUser({
          username: this.email,
          password: this.password,
          nickname: this.nickname,
          company_info: {
            name: this.company_name
          }
        })
        this.setSnackbar({
          type: 'success',
          msg: 'Konto opprettet. Sjekk innboksen for bekreftelse'
        })
        this.done = true
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    },
    async resend() {
      this.loading = true
      try {
        await this.resendConfirmation({
          username: this.email
        })
        this.setSnackbar({
          type: 'success',
          msg: 'Bekreftelses-e-post sendt på nytt'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
